import './index.css'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useContext } from 'react';


function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className='faq_header' onClick={decoratedOnClick}>
            <button className={isCurrentEventKey ? 'b_active' : ''}>{isCurrentEventKey ? ' - ' : ' + '} </button>
            <h3>{children} </h3>
        </div>

    );
}

function Faq() {
    return (
        <div id='faq' className="faq_container">
            <h1 className='theme-heading'> FAQ </h1>
            <div>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="0">What is Platinum Pilot CLub?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>Platinum Pilots Club is the name of the Club, all holders of the Platinum Pilots NFT are members of. It comes with a passionate community, and 
exclusive benefits, that the community will profit from and the one and only goal to take you to paradise. Pilots, travel and luxury are the key terms 
regarding the project and that will be found and prioritized consistently throughout the project. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="1">What Blockchain is it on? </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>The collection will be run on the Ethereum Blockchain, and NFTs will be purchased using ETH.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="2">What can you do with your Platinum Pilot?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>As a holder of one or more Platinum Pilots, you automatically become a member of our Platinum Pilots Club. You can bring your Platinum Pilot to life 
by profiting from the incentives of the club. Have a look at the Clubinformation to find out more.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="3">When is the official launch?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>The collection will launch at the end of june. Join our discord and stay tuned to find out the exact date. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="5">How do I join the pre-sale list?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>Join our discord and stay tuned to get regular updates on everything regarding pre-sale and whitelist. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="6">What is the mint price of a Platinum Pilot NFT?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body>Join our discord and stay tuned to get regular updates on everything regarding the mint price for whitelist members as well as public mint.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>


    )
}

export default Faq;
import "./style.css"
import img from "../../assets/images/11.jpg"
import gif from "../../assets/images/INT.gif"

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from 'react';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useInView } from 'react-intersection-observer';
import useWindowDimensions from "./responsive";
const fade_top = keyframes`
from {
    opacity: 0;

  }

  to {
    opacity: 1;

  }
`;

function Departure() {
    const [isLoading, setIsLoading] = useState(true);
    const [display, seDisplay] = useState(false);
    const { width } = useWindowDimensions();
    const { ref } = useInView({
        /* Optional options */
        threshold: 0,
        onChange() {
            seDisplay(true)
        }
    });

    useEffect(() => {
        if (width >= 769) {
            if (display != undefined) {
                setTimeout(() => {
                    seDisplay(undefined)
                }, 1200)
            }
        }
        else {
            seDisplay(false)
        }

    }, [display])

    const renderTooltip = props => {
        return (
            <Tooltip style={{
                fontFamily: `'rocketpop', 'Fira Sans', 'sans - serif'`,
                fontSize: '28px',
                color: 'inherit',
                borderRadius: 1,
            }} >{props}</Tooltip>
        )
    };
    function onLoad() {
        setIsLoading(false)
    }
    return (
        <div ref={ref} id='club' className="departure_img">

            <Reveal triggerOnce={true} keyframes={fade_top}>
                {/* <img src={img11} alt="" /> */}

                <img
                    alt="ad1"
                    src={img}
                    style={{ display: isLoading ? "block" : "none" }}
                />
                <img
                    alt="ad2"
                    src={gif}
                    style={{ display: isLoading ? "none" : "block" }}
                    onLoad={onLoad}
                />

                <div className="departure_content">
                    <h1>Welc0me t0 Platinum Pil0ts Club! <br />
                        Make y0urself at h0me! </h1>
                </div>

                <OverlayTrigger show={display} placement="top" overlay={renderTooltip('Click t0 Visit etherisc  .c0m')}>
                    <div className="etherisc display_none_" onClick={() => window.open('http://etherisc.com/', "_blank")}></div>
                </OverlayTrigger>

                <OverlayTrigger show={display} placement="top" overlay={renderTooltip('Click t0 Visit vitalik.ca')}>
                    <div className="vitalik display_none_" onClick={() => window.open('http://vitalik.ca/', "_blank")}></div>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={renderTooltip('Click t0 Visit vitalik.ca')}>

                    <div className="vitalik2 display_none_" onClick={() => window.open('http://vitalik.ca/', "_blank")}> </div>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={renderTooltip('Click t0 Visit vitalik.ca')}>
                    <div className="vitalik3 display_none_" onClick={() => window.open('http://vitalik.ca/', "_blank")}></div>
                </OverlayTrigger>
                <OverlayTrigger show={display} placement="top" overlay={renderTooltip('Click t0 Visit 0penSea')}>
                    <div className="suitcase display_none_" onClick={() => window.open('https://opensea.io/PlatinumPilotsClub', "_blank")}></div>
                </OverlayTrigger>

                <OverlayTrigger show={display} placement="top" overlay={renderTooltip('J0in us 0n Disc0rd')}>
                    <div className="jukebox_machine display_none_" onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', "_blank")}></div>
                </OverlayTrigger>

                <OverlayTrigger show={display} placement="top" overlay={renderTooltip('J0in us 0n Disc0rd')}>
                    <div className="backstage_sign display_none_" onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', "_blank")}></div>
                </OverlayTrigger>

            </Reveal>
        </div>
    )
}

export default Departure;
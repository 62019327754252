import './index.css'
import img3 from '../../assets/images/398.png'
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;




function Artist() {
  return (
    <div className='artist_container' id='artist'>
      <div className='artist_image_container'>
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={img3} alt='' />
        </Reveal>

      </div>
      <div className='artist_content_container'>
        <Reveal triggerOnce={true} keyframes={fade_right}>
          <h1>ARTIST</h1>
          <p>
            Gregor Forster is a trained freelance illustrator, based near Zürich, Switzerland. His projects range from illustrating colorful children’s and educational books as well as illustrations for advertising and editorial projects. Working closely with Decentral Parc to bring their vision of a NFT collection and its visual world to life is now part of this range.
          </p>
        </Reveal>
      </div>
    </div>
  )
}

export default Artist;
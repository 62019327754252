import './index.css'
import LOGO from '../../assets/images/logo.png'
// import FOOTER from '../../assets/images/footer.png'
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

function Footer() {
    return (
        <div className='footer_container_bg'>
            <Reveal triggerOnce={true} keyframes={fade_}>
                <div className='footer_container'>

                    <div className='footer_logo_container'>
                        <img src={LOGO} alt='' />
                    </div>
                    <div className='footer_content_container'>
                        <p>
                            Become a member of the first exclusive Pilot Club and travel the world using your own unique
                            Platinum PilotsClub NFT! Take your unique Platinum Pilots NFT on an unforgettable journey around
                            the world and gain access to the exclusive Platinum Pilots Club. Life changing experiences, an
                            amazing community and luxury trips are waiting for you! Sign up now for limited spots on the pre-sale
                            list!
                        </p>
                    </div>
                    <div className='footer_social_container'>
                        <span onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', '_blank')}>
                            Discord
                        </span>
                        <span onClick={() => window.open('https://form.typeform.com/to/jr1uyPx5?', '_blank')}>
                            Whitelist
                        </span>
                        <span onClick={() => window.open('https://www.instagram.com/platinumpilotsclub/', '_blank')}>
                            Instagram
                        </span>
                        <span onClick={() => window.open('https://twitter.com/platinumpilots', '_blank')}>
                            Twitter
                        </span>
                      
                    </div>
                    <div className='footer_credits'>
                        © 2022 PLATINUM PILOTS CLUB. ALL RIGHTS RESERVED
                    </div>
                </div>

            </Reveal>

        </div >
    )
}

export default Footer;

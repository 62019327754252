import './index.css'

import { FaTwitter, FaInstagram, FaAngleUp } from 'react-icons/fa';
import { BiMenu, BiMenuAltRight } from 'react-icons/bi';
import { SiDiscord, SiInstagram, SiTwitter } from 'react-icons/si';

import { AiOutlineMenu } from 'react-icons/ai'
import { VscChromeClose } from 'react-icons/vsc'

import LOGO from '../../assets/images/logo.png'
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';


function Navbar() {
    const [toggle, setToggle] = useState(false)
    const handleClick = (value) => {
        setToggle(!value)
    }
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        const anchor = document.querySelector('.anchor_container');
        scrollTop <= 220 ? anchor.classList.add('display_none') : anchor.classList.remove('display_none');
    };

    return (
        <>
            <div className='Navbar_container'>
                <div className='second_nav_section'>
                    <div className='logo_container'>
                        <img src={LOGO} alt='' />
                    </div>
                    <div className='menu_container'>

                        <span>
                            <HashLink smooth to={'#about'}>ABOUT</HashLink>
                        </span>
                        <span>
                            <HashLink smooth to={'#artist'}>ARTIST</HashLink>
                        </span>

                        <span>
                            <HashLink smooth to={'#roadmap'}>ROADMAP</HashLink>
                        </span>
                        <span>
                            <HashLink smooth to={'#team'}>TEAM</HashLink>
                        </span>

                        <span>
                            <HashLink smooth to={'#club'}>THE CLUB</HashLink>
                        </span>
                        <span>
                            <HashLink smooth to={'#faq'}>FAQ</HashLink>
                        </span>
                    </div>

                    <div className='nav_button_container'>
                        <div className='nav_icons_container'>
                            <span onClick={() => window.open('https://twitter.com/platinumpilots', '_blank')}>
                                <FaTwitter />
                            </span>
                            <span onClick={() => window.open('https://www.instagram.com/platinumpilotsclub/', '_blank')}>
                                <FaInstagram />
                            </span>
                        </div>
                        <button className='whitelist' onClick={() => window.open('https://form.typeform.com/to/jr1uyPx5?', '_blank')}>
                            WHITELIST
                        </button>
                        <button onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', '_blank')}>
                            JOIN US ON DISCORD
                        </button>
                    </div>
                </div>
                <div className='anchor_container'>
                    <HashLink to='#' smooth className='back-to-top-button'>
                        <FaAngleUp />
                    </HashLink>
                </div>

            </div>
            <div className="mobile_navbar_container">
                <div className='m_logo'>
                    <img src={LOGO} alt='' />

                </div>
                <div className="icons_container">
                    <span onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', '_blank')}><SiDiscord /></span>
                    <span onClick={() => window.open('https://twitter.com/platinumpilots', '_blank')}><SiTwitter /></span>
                    <span onClick={() => window.open('https://www.instagram.com/platinumpilotsclub/', '_blank')}><SiInstagram /></span>
                    <span onClick={() => handleClick(toggle)} className="toggle_btn">
                        {toggle ? <VscChromeClose /> : <AiOutlineMenu />}
                    </span>
                </div>
                {toggle ?
                    <div className="menu_section_mobile">
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#about'}>ABOUT</HashLink>
                        </span>

                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#artist'}>ARTIST</HashLink>
                        </span>

                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#roadmap'}>ROADMAP</HashLink>
                        </span>

                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#team'}>TEAM</HashLink>
                        </span>

                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#club'}>THE CLUB</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'#faq'}>FAQ</HashLink>
                        </span>
                        <span >
                            <button className='m_whitelist' onClick={() => window.open('https://form.typeform.com/to/jr1uyPx5?', '_blank')}>
                                WHITELIST
                            </button>
                            <button className='join_btn' onClick={() => window.open('https://discord.com/invite/platinumpilotsclub', '_blank')}>
                                JOIN US ON DISCORD
                            </button>
                        </span>
                    </div>
                    : null
                }
            </div>
        </>
    )
}

export default Navbar;


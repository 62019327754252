import "./style.css"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const fade_top = keyframes`
from {
    -webkit-transform: translate3d(0, 80px, 0);
    transform: translate3d(0, 80px, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
`;


function Who() {
    return (
        <div id="about" className="t_about">
            <div className="intro_container">
                <Reveal triggerOnce={true} keyframes={fade_top}>

                    <h1>Who Are We</h1>
                    <p>It's been two years. Borders are opening. Travel is resuming. Becoming a member of the exclusive
                        Platinum Pilots Club is your best chance to celebrate freedom and to take a trip to the sun. Who
                        doesn’t want to experience luxury travel, meet like-minded people, see the most beautiful and unreal
                        places on earth and make unforgettable memories that will last a lifetime? We consider it our mission
                        to make all this possible for you. Forget about budget and planning headaches and leave all of that to
                        us. Get your hands on one of our 9,999 NFTs, which unlocks not only private club membership but
                        additional benefits along with your holdership. Let your pilot take you to paradise in the sun. The beau
                        monde life is waiting for you!<br />
                        The Platinum Pilots NFTs come with over 145 traits! The collection will be run on the Ethereum
                        Blockchain, and NFTs will be purchased using ETH.</p>
                </Reveal>
            </div>
        </div>

    )
}

export default Who;
import Artist from "../components/artist";
import Banner from "../components/banner";
import Cover from "../components/cover";
import Departure from "../components/departure";
import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import Packages from "../components/packages";
import Roadmap from "../components/roadmap";
import Team from "../components/team";
import Who from "../components/who_section";


function Home() {
    return (
        <div className='App'>
            <Navbar />
            <Banner/>
            <Cover />
            <div className="bg_1">
                <Who />
                <Artist />
                <Roadmap />
                <Team />
            </div>
            <Departure />
            <div className="bg_5">
                <Packages />
                <Faq />
            </div>
            <Footer />
        </div >
    );
}

export default Home;
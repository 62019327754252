import './index.css'
import img1 from '../../assets/images/351.png'
import img2 from '../../assets/images/302.png'
import img3 from '../../assets/images/429.png'
import img4 from '../../assets/images/844.png'
import React from 'react'
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_top = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

const fade_down = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;


function Team() {

    let data = [
        [
            'Obi Wan',
            "Project Management",
            `Being an experienced
                entrepreneur and coder, he wrote
                his first smart contracts back in
                2015. With a strong background in
                finance and banking, but with
                passion for art, design and travel,
                he started PlatinumPilotsClub
                when he met Gregor, our artist.
            `,
            img2
        ],

        [
            'Leia',
            "Project Lead",
            `With a fresh mind and
                innovative ideas, Leia is the
                one to bring the individual
                components of our project
                together while adding her
                own youthful spark.
            `,
            img1
        ],
        [
            'Sunyata',
            "Blockchain",
            `Starting coding at the age
                of 10, quickly after his PhD,
                he created Web2 tech
                companies, platforms and
                quickly evolved to Web3
                and blockchain
                development supporting
                high potential companies
                and projects.`, img4
        ],
        [
            'Claudia',
            "Travel Agent",
            `Claudia, as our travel
                agent, is running a diving
                shop in Bali and has a lot of
                local experience of
                Indonesia and South East
                Asia. She’ll support our
                club members in finding the
                most exclusive hot spots in
                the area turn your dreams
                into reality.`, img3
        ]

    ]

    return (
        <div className='team_container' id='team'>
            <div className='team_heading'>
                <h1>THE TEAM</h1>
            </div>

            <div className='team_member_wrapper'>
                {data.map((v, i) => {
                    return (
                        <div key={i} className='team_member'>
                            <Reveal triggerOnce={true} keyframes={fade_top}>
                                <div className='team_image'>
                                    <img src={v[3]} alt='' />
                                </div>
                                <h3>{v[0]}</h3>
                                <h5>{v[1]}</h5>
                                <p>{v[2]}</p>
                            </Reveal>
                        </div>
                    )

                })}
            </div>
        </div>
    )
}

export default Team;


import './index.css'
import img119 from "../../assets/images/119.jpg"
import img120 from "../../assets/images/120.jpg"


function Banner(){
    return(
        <div className='banner_container'>
            <div className='mob_image'>
                <img src={img119} alt="" />
            </div>
            <div className='dekstop_image'>
            <img src={img120} alt="" />
            </div>
        </div>
    )
}

export default Banner;
import './index.css'
import img from '../../assets/images/10.jpg'
import gif from '../../assets/images/EXT.gif'
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useInView } from 'react-intersection-observer';
import useWindowDimensions from '../departure/responsive';
const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


function Cover() {
  const [isLoading, setIsLoading] = useState(true);
  const [display, seDisplay] = useState(false);
  const { height, width } = useWindowDimensions();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      seDisplay(true)
    }
  });

  useEffect(() => {
    if (width >= 769) {
      if (display != undefined) {
        setTimeout(() => {
          seDisplay(undefined)
        }, 1200)
      }
    }
    else {
      seDisplay(false)
    }
  }, [display])
  function onLoad() {
    setIsLoading(false)
  }

  return (
    <div ref={ref} className='cover_container'>
      <Reveal triggerOnce={true} keyframes={fade_}>
        <div className='image_cnt'>
          <h1>It's been tw0 years. B0rders are 0pening. Travel is resuming.</h1>
          <img
            alt="ad1"
            src={img}
            style={{ display: isLoading ? "block" : "none" }}
          />
          <img
            alt="ad2"
            src={gif}
            style={{ display: isLoading ? "none" : "block" }}
            onLoad={onLoad}
          />

          <div className='enter_map display_none_'>
            <OverlayTrigger
              show={display}
              placement="top"
              overlay={<Tooltip style={{
                fontFamily: `'rocketpop', 'Fira Sans', 'sans - serif'`,
                fontSize: '28px',
                color: 'inherit',
                borderRadius: 1,
              }}
                id="button-tooltip-2">Click t0  Enter</Tooltip>}
            >
              <HashLink to={'#club'} smooth></HashLink>
            </OverlayTrigger>
          </div>

        </div>
      </Reveal>

    </div >
  )
}

export default Cover;
import './index.css'
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;


const data = [
    {
        num: '01',
        heading: 'The Artwork',
        para: `This project started when we met Gregor in November 2021 after the successful launch 
        of the Swiss crypto stamp which was sold out in two hours. We were immediately 
        drawn into the unique style and expressiveness of Gregors art. We worked for months 
        to let this collection stand out by its care for utmost detail, color and humor. Gregor will 
        continue to provide beautiful works of art in the future, exclusively for the pilots club.`,
    },
    {
        num: '02',
        heading: 'The Club',
        para: `Becoming a member of our exclusive club will open countless doors for you. Once you 
        are a member, you will continuously benefit from our member-only privileges.
        If you are used to the amenities of first class airline service, you’re right here! Have a 
        closer look to some of them right here on the website.`,
    }, {
        num: '03',
        heading: 'Travel Information',
        para: `You’re looking for exclusive travel secrets for whatever place you envision as paradise? 
        From hidden, unexplored places, to unforgettable experiences, there is something for 
        all those looking out for unique and authentic adventures.`,
    }, {
        num: '04',
        heading: 'The Packages',
        para: `We have bundled our travel gems in well thought out, all-inclusive packages which will 
        provide you with carefree travel experience. These will be raffled off among our NFT 
        holders.The sooner you become a holder, the better your chances of winning a trip to paradise.`,
    }, {
        num: '05',
        heading: 'IRL Items',
        para: `In addition, we give our members exclusive access to merch and real-life items such as 
        prestigous and luxurious pilots badges and branded clothing, showing of your personal 
        NFT. It is important to us that your ideas and wishes are 
        also given a voice and implemented in the creative process of this development.`,
    }, {
        num: '06',
        heading: 'Activities',
        para: `We are continuously working on additional benefits for our club members. Most 
        importantly, we want to bring you together and live the exclusive travel life in a 
        community of like-minded people, ready to share experiences, ideas and knowledge 
        among themselves. The Platinum Pilots Club is waiting for you!`,
    },
    , {
        num: '07',
        heading: 'The Future',
        para: ``,
    },
]

function RoadSection({ num, heading, para }) {
    return (

        < div className='r_row' >
            <div className='r_num'>
                {num}
                <div className='r_bullet'>
                    <div className='r_line'></div>
                </div>
            </div>
            <div className='r_content'>
                <h1>{heading}</h1>
                <p>
                    {para}
                </p>
            </div>
        </div >

    )
}


function Roadmap() {
    return (
        <div className='roadmap_container_bg' id='roadmap'>
            <div className='roadmap_container'>
                <Reveal triggerOnce={true} keyframes={fade_}>
                    <div className='roadmap_content'>
                        <h1>ROAD MAP</h1>
                        <p>When you buy a Platinum Pilot, you're not simply buying a rare piece of art. You are buying into a club whose benefits will increase over time. Your Platinum Pilot can become your digital identity, opening digital AND real-life opportunities for you.</p>
                    </div>
                </Reveal>

                <Reveal triggerOnce={true} keyframes={fade_}>

                    <div className='roadmap_sections'>
                        {
                            data.map((v, i) => {
                                return (
                                    <RoadSection key={i} heading={v.heading} num={v.num} para={v.para} />
                                )
                            })
                        }
                    </div>
                </Reveal>

            </div>
        </div>
    )
}

export default Roadmap;
import "./style.css"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_left = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(1p0px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;
const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(=100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;



function Packages() {

    return (
        <div id="packages" className="package_container">
            <h1 className="main_h">
            The Platinum Pilots Club
            </h1>
            <div className='pak_wrapper'>
                <div className='line_'>

                </div>
                <div className='l_section'>
                    <Reveal triggerOnce={true} keyframes={fade_right}>
                        <h1>
                            The Platinum Pilots Club
                        </h1>
                        <p>
                            Be part of our passionate community. Let’s
                            collect our ideas together, incorporating your
                            opinions and visions into future drops.
                            Take advantage of the partnerships we're building
                             with travel blogs, local experts and some of the 
                             world's most extraordinary destinations to deliver
                             a unique experience to you.</p>
                    </Reveal>
                </div>
                <div className='r_section'>
                    <Reveal triggerOnce={true} keyframes={fade_left}>
                        <h1>
                            Travel to paradise
                        </h1>
                        <p>
                            In a raffle exclusively for NFT holders you have
                            the chance to win your vacation in paradise. As
                            a team, we will take care of all the planning,
                            making your trip headache-free. This is your
                            chance to experience a unique and
                            extraordinary time that you will remember
                            forever. Let us take you to paradise!

                        </p>
                    </Reveal>
                </div>

                <div className='l_section'>
                    <Reveal triggerOnce={true} keyframes={fade_right}>
                        <h1>
                            Our responsibility
                        </h1>
                        <p>
                            Through believing that Crypto is essentially
                            borderless its important to us to offer packages
                            that add social value: The Animal Support
                            package, Reef Project package and the Local
                            Tourist Support package. This is your chance to
                            do something world-changing by buying one of
                            our NFTs.  </p>
                    </Reveal>
                </div>
                <div className='r_section'>
                    <Reveal triggerOnce={true} keyframes={fade_left}>
                        <h1>
                            The packages
                        </h1>
                        <p>
                            For you, as our club member, we have put
                            together six travel packages. We still want to
                            keep the contents of these secret. However, we
                            can give you a small sneak peek. Fantastic
                            hotels, diving courses and pilot-related
                            challenges are waiting for you. The earlier you
                            buy, the better your chances of winning!
                        </p>
                    </Reveal>
                </div>
            </div>
        </div>
    )
}

export default Packages;
